import firstImg from "../../assets/images/1.png";
import secondImg from "../../assets/images/2.png";
import thirdImg from "../../assets/images/3.png";
import fourthImg from "../../assets/images/4.png";
import fifthImg from "../../assets/images/5.png";
import sixthImg from "../../assets/images/6.png";
import seventhImg from "../../assets/images/7.png";
import eightImg from "../../assets/images/8.png";
import nineImg from "../../assets/images/9.png";
import tenImg from "../../assets/images/10.png";
import elevenImg from "../../assets/images/11.png";
import twelveImg from "../../assets/images/12.png";
import thirteenImg from "../../assets/images/13.png";

const ProfilePageWrapper = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img src={firstImg} alt="first" />
      <img src={secondImg} alt="second" />
      <img src={thirdImg} alt="third" />
      <img src={fourthImg} alt="fourth" />
      <img src={fifthImg} alt="fifth" />
      <img src={sixthImg} alt="six" />
      <img src={seventhImg} alt="seven" />
      <img src={eightImg} alt="right" />
      <img src={nineImg} alt="nine" />
      <img src={tenImg} alt="ten" />
      <img src={elevenImg} alt="eleven" />
      <img src={twelveImg} alt="twelve" />
      <img src={thirteenImg} alt="thirteen" />
    </div>
  );
};

export default ProfilePageWrapper;
