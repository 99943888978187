import classes from "./Footer.module.css";
import logo from "../../assets/svg/logo.svg";
import twitter from "../../assets/svg/twitter.svg";
import linkedin from "../../assets/svg/linkedin.svg";
import facebook from "../../assets/svg/facebook.svg";
import apple from "../../assets/svg/apple.svg";
import play from "../../assets/svg/play.svg";
import insta from "../../assets/svg/instagram.svg";
import { HashLink } from "react-router-hash-link";
import { Menu, Dropdown } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.footer__logo}>
        <Link to="/">
          <img src={logo} alt="Valify Logo" />
        </Link>
        <span>2021. All rights reserved</span>
      </div>
      <div className={classes.footer__menu}>
        <h3>Menu</h3>
        <div className={classes.footer__grid}>
          <span>
            <HashLink to="/#about" style={{ color: "#d0cde5" }}>
              About us
            </HashLink>
          </span>
          <span>
            <HashLink to="/#industries" style={{ color: "#d0cde5" }}>
              Industries
            </HashLink>
          </span>
          <span>
            <HashLink to="/#showcase" style={{ color: "#d0cde5" }}>
              Showcase App
            </HashLink>
          </span>
          <span>
            <HashLink to="/#usecases" style={{ color: "#d0cde5" }}>
              Use cases
            </HashLink>
          </span>
          <span>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <a
                      href="https://valify.gitbook.io/documentation/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3 style={{ fontWeight: "bold" }}>API Documentation</h3>
                      <p style={{ fontWeight: "300" }}>
                        Instructions on API Integration
                      </p>
                    </a>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <a
                      href="https://valify.gitbook.io/valify-ios-sdk-documentation"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3 style={{ fontWeight: "bold" }}>SDK Documentation</h3>
                      <p style={{ fontWeight: "300" }}>
                        Instructions on Mobile SDK Integration
                      </p>
                    </a>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                href="/"
                style={{ color: "rgb(208, 205, 229)" }}
              >
                Developers <UpOutlined style={{ fontSize: "1.3rem" }} />
              </a>
            </Dropdown>
          </span>

          <span>
            <HashLink to="/terms-of-use#terms" style={{ color: "#d0cde5" }}>
              Terms & Conditions
            </HashLink>
          </span>
        </div>
      </div>
      <div className={classes.footer__contacts}>
        <h3>Contact us</h3>
        <div className={classes.footer__grid}>
          <span>St. 18, Maadi, Building 22</span>
          <span>Cairo, Egypt</span>
          <span>info@valify.me</span>
        </div>
      </div>

      <div className={classes.footer__social}>
        <h3>Social Media</h3>
        <div className={classes.social}>
          <a
            href="https://twitter.com/Valifyme"
            style={{ color: "#d0cde5" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter logo" />
          </a>

          <a
            href="https://www.linkedin.com/company/valifysolutions/mycompany/"
            style={{ color: "#d0cde5" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin logo" />
          </a>

          <a
            href="https://www.facebook.com/ValifySolutions"
            style={{ color: "#d0cde5" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook logo" />
          </a>

          <a
            href="https://www.instagram.com/valify.me/"
            style={{ color: "#d0cde5" }}
            target="_blank"
            rel="noreferrer"
          >
            <object
              type="image/svg+xml"
              data={insta}
              aria-label="instagram logo"
              className={classes.insta}
            />
          </a>
        </div>
        <h3>Mobile App</h3>
        <div className={classes.social}>
          <a
            href="https://apps.apple.com/eg/app/valify/id1589030299"
            target="_blank"
            rel="noreferrer"
          >
            <img src={apple} alt="apple logo" />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=me.valify.valifyshowcaseapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={play} alt="google play logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
