import React, { useState, useMemo } from "react";
import countryList from "react-select-country-list";
import toast from "react-hot-toast";
import { Modal, Select, Button } from "antd";
import classes from "./Modal.module.css";
import axios from "axios";
import { RollbackOutlined } from "@ant-design/icons";
import ReactGA from "react-ga";

const { Option } = Select;

const options = [
  {
    label: "OCR",
    value: "OCR",
  },
  {
    label: "Face Recognition",
    value: "Face Recognition",
  },
  {
    label: "Document Verification",
    value: "Document Verification",
  },
  {
    label: "Liveness",
    value: "Liveness",
  },
];

const ModalComp = ({ visible, onOk, onCancel }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [value, setValue] = React.useState([]); // Services
  const [countryValue, setCountryValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [volume, setVolume] = useState("");
  // const [useCase, setUseCase] = useState("");
  const [useCaseBrief, setUseCaseBrief] = useState("");
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [showresendMail, setShowresendMail] = useState(false);
  const [resendMail, setResendMail] = useState("");

  const countyOptions = useMemo(() => countryList().getData(), []);

  const changeCountryHandler = (value) => {
    setCountryValue(value);
  };

  const handleChangeVolume = (value) => {
    setVolume(value);
  };

  const handleShowResendEmail = () => {
    setShowresendMail(true);
  };

  const resendMailHandler = async () => {
    if (resendMail) {
      try {
        await axios.post(
          `/sGsBE3l0SSPsTJzfenZN0JgLmRuVmZZT/v1/resend-verification-email/`,
          { email: resendMail },
          {
            headers: {
              withCredentials: true,
            },
          }
        );
        setResendMail("");
        onCancel();
        toast.success("Verification sent. Please check your email inbox.");
      } catch (e) {
        if (e.response) {
          if (e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong plaese try again later!");
          }
        } else {
          toast.error("Please make sure of your internet connections!");
        }
      }

      setShowresendMail(false);
    } else {
      toast.error("Please enter your email!");
    }
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      organization_name: organisationName,
      country: countryValue,
      services_of_interest: JSON.stringify(value),
      estimated_volume: volume,
      use_case: ".",
      use_case_brief: useCaseBrief,
      phone_number: phoneNumber,
      organization_type: "test",
    };

    if (
      name &&
      email &&
      organisationName &&
      countryValue &&
      value &&
      volume &&
      // useCase &&
      useCaseBrief &&
      phoneNumber
    ) {
      setbuttonDisabled(true);
      try {
        await axios.post(
          `/sGsBE3l0SSPsTJzfenZN0JgLmRuVmZZT/v1/register-user/?lang=en`,
          data,
          {
            headers: {
              withCredentials: true,
            },
          }
        );
        setName("");
        setEmail("");
        setCountryValue(undefined);
        setOrganisationName("");
        setUseCaseBrief("");
        setValue(undefined);
        setVolume(undefined);
        setPhoneNumber("");
        onCancel();
        toast.success(`Registration Successful! Please check your Inbox.
If it’s not there, then please check your spam folder.`);
        ReactGA.event({
          category: "Client",
          action: "Registered on the website",
        });
      } catch (e) {
        if (e.response) {
          if (e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong plaese try again later!");
          }
        } else {
          toast.error("Please make sure of your internet connections!");
        }
      }
    } else {
      toast.error("Please fill all fields!");
    }
    window.scrollTo(0, 0);
    setbuttonDisabled(false);
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "55%",
    },
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
    },
    placeholder: "Pick Services of Interest",
    maxTagCount: "responsive",
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[]}
      width="55%"
    >
      <div className={classes.modal}>
        {showresendMail ? (
          <>
            <Button
              onClick={() => {
                setShowresendMail(false);
                setResendMail("");
              }}
            >
              <RollbackOutlined /> Back
            </Button>
            <h2 style={{ margin: "2rem 0 3rem", textAlign: "center" }}>
              If you haven't received a verification email yet, enter your email
              below
            </h2>
            <div style={{ textAlign: "center" }}>
              <input
                type="email"
                value={resendMail}
                onChange={(e) => setResendMail(e.target.value)}
                placeholder="Your Email"
                className={classes.modal__emailInput}
              />

              <button
                className={classes.modal__resend}
                onClick={resendMailHandler}
              >
                Send
              </button>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "3rem",
              }}
            >
              <h2>Register now & get full access to the Valify Showcase</h2>
              <button
                className={classes.modal__muted}
                onClick={handleShowResendEmail}
              >
                Already Registered?
              </button>
            </div>
            <form className={classes.modal__form} onSubmit={handleSubmit}>
              <div className={classes.modal__form__header}>
                <div>
                  <label>Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Your Name"
                  />
                </div>

                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your Email"
                  />
                </div>

                <div>
                  <label>Organisation Name</label>
                  <input
                    type="text"
                    value={organisationName}
                    onChange={(e) => setOrganisationName(e.target.value)}
                    placeholder="Your Organisation"
                  />
                </div>
              </div>

              <div className={classes.flex__div} style={{ display: "flex" }}>
                <div className={classes.country}>
                  <label style={{ marginBottom: "2rem" }}>Country</label>
                  <Select
                    showSearch
                    value={countryValue || undefined}
                    optionFilterProp="label"
                    style={{ width: "100%" }}
                    placeholder="Select Your Country"
                    size="middle"
                    onChange={changeCountryHandler}
                    options={countyOptions}
                    filterOption
                    allowClear
                  ></Select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "3.5rem",
                  }}
                  className={classes.phoneNumber}
                >
                  <label>Phone Number</label>
                  <input
                    type="number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Your Phone Number"
                  />
                </div>
              </div>

              <div className={classes.services_of_interest}>
                <label>Services of Interest</label>
                <Select
                  {...selectProps}
                  listHeight="10%"
                  size="middle"
                  value={value}
                  allowClear
                />
              </div>

              <div className={classes.services_of_interest}>
                <label style={{ marginRight: "5.5rem" }}>
                  Estimated Monthly Volume
                </label>
                <Select
                  size="middle"
                  onChange={handleChangeVolume}
                  placeholder="Select Your Range"
                  style={{ width: "30%" }}
                  value={volume || undefined}
                  allowClear
                >
                  <Option value="0-1000">0 - 1,000</Option>
                  <Option value="1000-10000">1,000 - 10,000</Option>
                  <Option value="10000-100.000">10,000+</Option>
                </Select>
              </div>

              <div className={classes.modal__form__footer}>
                <label>Use Case</label>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="Write Your Message..."
                  value={useCaseBrief}
                  onChange={(e) => setUseCaseBrief(e.target.value)}
                />
              </div>

              <button
                className={classes.Modal__btn}
                disabled={buttonDisabled}
                style={{ backgroundColor: buttonDisabled ? "grey" : "#62cbc9" }}
              >
                Register
              </button>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalComp;
