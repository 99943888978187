import classes from "./Header.module.css";
import logo from "../../assets/svg/logo.svg";
import headerImg from "../../assets/images/header-photo.png";
import elipses from "../../assets/svg/Group 49.svg";
import star from "../../assets/svg/Star 1.svg";
import vector from "../../assets/svg/Vector (1).svg";
import goldenStar from "../../assets/svg/Star 2.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import contact from "../../assets/svg/contact.svg";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Navbar = ({ openModal }) => {
  return (
    <header className={classes.header}>
      <nav className={classes.header__nav}>
        <div className={classes.header__logo}>
          <Link to="/">
            <img src={logo} alt="Valify Logo" />
          </Link>
        </div>

        <input
          type="checkbox"
          className={classes.header__checkbox}
          id="nav-toggler"
        />

        <label
          htmlFor="nav-toggler"
          className={classes.header__button}
          aria-label="navigation toggler"
        >
          <span className={classes.header__hamburger}> </span>
        </label>

        <ul className={classes.header__links}>
          <li className={classes.header__link}>
            <HashLink to="/#about" style={{ color: "#fff" }}>
              About Us
            </HashLink>
          </li>
          {/* <li className={classes.header__link}>
            <HashLink to="#clients" style={{ color: "#fff" }}>
              Clients
            </HashLink>
          </li> */}
          {/* <li className={classes.header__link}>
            <HashLink to="#partners" style={{ color: "#fff" }}>
              Partners
            </HashLink>
          </li> */}
          <li className={classes.header__link}>
            <HashLink to="/#industries" style={{ color: "#fff" }}>
              Industries
            </HashLink>
          </li>
          <li className={classes.header__link}>
            <HashLink to="/#showcase" style={{ color: "#fff" }}>
              Showcase App
            </HashLink>
          </li>
          <li className={classes.header__link}>
            <HashLink to="/#usecases" style={{ color: "#fff" }}>
              Use Cases
            </HashLink>
          </li>
          {/* <li className={classes.header__link}>
            <a
              href="https://valify.gitbook.io/valify-ios-sdk-documentation"
              style={{ color: "#fff" }}
              target="_blank"
              rel="noreferrer"
            >
              Developers
            </a>
          </li> */}
          <li className={classes.header__link}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <a
                      href="https://valify.gitbook.io/documentation/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3 style={{ fontWeight: "bold" }}>API Documentation</h3>
                      <p style={{ fontWeight: "300" }}>
                        Instructions on API Integration
                      </p>
                    </a>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <a
                      href="https://valify.gitbook.io/valify-ios-sdk-documentation"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h3 style={{ fontWeight: "bold" }}>SDK Documentation</h3>
                      <p style={{ fontWeight: "300" }}>
                        Instructions on Mobile SDK Integration
                      </p>
                    </a>
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                href="/"
                style={{ color: "#fff" }}
              >
                Developers <DownOutlined />
              </a>
            </Dropdown>
          </li>
          <li className={classes.header__link}>
            <a
              href="https://valify-solutions.zohorecruit.com/jobs/Careers"
              rel="noopener"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >Careers</a>
          </li>
        </ul>
        <div className={classes.header_contact}>
          <HashLink to="#contact" style={{ color: "#fff" }}>
            Contact Us
          </HashLink>
        </div>
      </nav>

      <div className={classes.header__content}>
        <div className={classes.header__info}>
          <h1>
            User Identity{" "}
            <span className={classes.span__blue}> Verification </span> &
            Biometric <span> Authentication</span>
          </h1>
          <p>
            Discover how Digital Identity Verification can scale your business
            and help drive new growth through digital channels while maintaining
            regulatory compliance.
          </p>
          <a
            href="https://portal.valify.me/auth/signup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Try it out!</button>
          </a>
          <img
            src={vector}
            alt="background vector"
            className={classes.header__vector}
          />
          <img
            src={goldenStar}
            alt="golden star"
            className={classes.header__glodenStar}
          />
        </div>
        <div className={classes.header__img}>
          <img src={headerImg} alt="blonde girl smiles" />
          <img
            className={classes.header__elipses}
            src={elipses}
            alt="two elipses"
          />
          <img className={classes.header__star} src={star} alt="star" />
        </div>
      </div>

      <div className={classes.contact__icon}>
        <HashLink to="#contact" style={{ color: "#fff" }}>
          <img src={contact} alt="message icon" />
        </HashLink>
      </div>
    </header>
  );
};

export default Navbar;
