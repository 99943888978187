import classes from "./OurServices.module.css";
import dataExtraction from "../../assets/svg/dataExtraction.svg";
import faceRecogination from "../../assets/svg/face-recognition.svg";
import customer from "../../assets/svg/customer.svg";

const OurServices = () => {
  return (
    <div className={classes.OurServices}>
      <h2>OUR SERVICES</h2>
      <div className={classes.OurServices__info}>
        <h3>Digital Identity Verification</h3>
      </div>
      <div className={classes.OurServices__grid}>
        <div className={classes.OurServices__item}>
          <img src={dataExtraction} alt="digital cloud" />
          <div className={classes.OurServices__item__text}>
            <h4>Data Extraction & Document Verification</h4>
            <p>
              For any service provider facing risks of fraud, money-laundering,
              or funding of illicit activities.
            </p>
          </div>
        </div>

        <div className={classes.OurServices__item}>
          <img src={faceRecogination} alt="human head" />
          <div className={classes.OurServices__item__text}>
            <h4>Face Verification & Liveness Detection</h4>
            <p>
              We ensure that the person undergoing KYC is conscious and aware
              and not pre-recorded.
            </p>
          </div>
        </div>

        <div className={classes.OurServices__item}>
          <img src={customer} alt="digital cloud" />
          <div className={classes.OurServices__item__text}>
            <h4>Other Services</h4>
            <p>
              We also offer other services such as Sanction Scanning and
              Transliteration, as well as the ability to support custom
              documents.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
