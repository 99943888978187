import React from "react";
import classes from "./Terms.module.css";

const Terms = () => {
  return (
    <div className={classes.terms} id="terms">
      <h2>1. TERMS OF USE</h2>
      <p>
        By downloading, browsing, accessing or using this Sample Store mobile
        application (“Mobile Application”), you agree to be bound by these Terms
        and Conditions of Use. We reserve the right to amend these terms and
        conditions at any time where the user will be notified on the
        system/application. If you disagree with any of these Terms and
        Conditions of Use, you must immediately discontinue your access to the
        Mobile Application and your use of the services offered on the Mobile
        Application. Continued use of the Mobile Application will constitute
        acceptance of these Terms and Conditions of Use.
      </p>

      <h2>2. DEFINITIONS</h2>
      <p>
        In these Terms and Conditions of Use, the following capitalized terms
        shall have the following meanings, except where the context otherwise
        requires: “Process”: Means the process of Digital registration "Account"
        means an account created by a User on the Mobile Application as part of
        Registration. "Privacy Policy" means the privacy policy set out in
        Clause 10 of these Terms and Conditions of Use. "Register" means to
        create an Account and "Registration" means the act of creating such an
        Account. "Services" means all the services provided by Valify solutions
        via the Mobile Application to Users, and "Service" means any one of them
        "Users" means users of the Mobile Application, including you and "User"
        means any one of them. “OTP” Means One-time password which is sent to
        the user mobile phone “NID” Means National Egyptian Identification
        Document{" "}
      </p>

      <h2>3. GENERAL ISSUES ABOUT THE MOBILE APPLICATION AND THE SERVICES</h2>
      <p>
        3.1 Applicability of terms and conditions: The use of any Services
        and/or the Mobile Application and the making of any Redemptions are
        subject to these Terms and Conditions of Use. <br />
        3.2 Location: The Mobile Application and the Services are intended
        solely for use by Users who access the Mobile Application in Egypt. We
        make no representation that the Services (or any goods or services) are
        available or otherwise suitable for use outside of Egypt.
        Notwithstanding the above, if you access the Mobile Application, use the
        Services or make any Redemptions from locations outside Egypt, you do so
        on your own initiative and are responsible for the consequences and for
        compliance with all applicable laws.
        <br /> 3.3 Scope: The Mobile Application, the Services and any
        Redemptions are for your non-commercial, personal use only and must not
        be used for business purposes.
        <br /> 3.4 Prevention on use: We reserve the right to prevent you using
        the Mobile Application and the Service (or any part of them). <br />
        3.5 Equipment and Networks: The provision of the Services and the Mobile
        Application does not include the provision of a mobile telephone or
        handheld device or other necessary equipment to access the Mobile
        Application or the Services. To use the Mobile Application or Services,
        you will require Internet connectivity and appropriate telecommunication
        links. You acknowledge that the terms of agreement with your respective
        mobile network provider ("Mobile Provider") will continue to apply when
        using the Mobile Application. As a result, you may be charged by the
        Mobile Provider for access to network connection services for the
        duration of the connection while accessing the Mobile Application or any
        such third-party charges as may arise. You accept responsibility for any
        such charges that arise.
        <br /> 3.6 Permission to use Mobile Application: If you are not the
        recipient of account credentials for the mobile telephone or handheld
        device being used to access the Mobile Application, you should not be
        using the process.
        <br /> 3.7 License to Use Material: By submitting any text or images
        (including photographs) (“Material”) via the Application, you represent
        that you are the owner of the Material, You hereby grant us a worldwide,
        royalty-free, non-exclusive license to use the Material to be able to .
      </p>

      <h2>4. The process</h2>
      <p>
        4.1.1 ID Front capturing: The user is asked to take a photo of the front
        of the ID <br />
        4.1.2 ID Back capturing: The user is asked to take a photo of the back
        of the ID <br />
        4.2: The user approves the extracted data 4.3: The user approve/edit
        editable fields <br /> 4.4: The user performs some actions for face
        comparison with National ID <br /> 4.5: The user enters their mobile
        number to receive the OTP.
      </p>

      <h2>5. YOUR OBLIGATIONS</h2>
      <p>
        5.1 Accurate information: You warrant that all information provided on
        Registration and contained as part of your Account is true, complete and
        accurate and that you will promptly inform us of any changes to such
        information. <br /> 5.2 Content on the Mobile Application and Service:
        It is your responsibility to ensure that any products, samples or
        information available through the Mobile Application or the Services
        meet your specific requirements. <br /> 5.3 Prohibitions in relation to
        usage of Services or Mobile Application: Without limitation, you
        undertake not to use or permit anyone else to use the Services or Mobile
        Application: - <br /> 5.3.1 to send or receive any material which is not
        civil or tasteful <br /> 5.3.2 to send or receive any material which is
        threatening, grossly offensive, of an indecent, obscene or menacing
        character, blasphemous or defamatory of any person, in contempt of court
        or in breach of confidence, copyright, rights of personality, publicity
        or privacy or any other third-party rights; <br /> 5.3.3 to send or
        receive any material for which you have not obtained all necessary
        licenses and/or approvals (from us); or which constitutes or encourages
        conduct that would be considered a criminal offence, give rise to civil
        liability, or otherwise be contrary to the law of or infringe the rights
        of any third party in any country in the world; <br /> 5.3.4 to send or
        receive any material which is technically harmful (including computer
        viruses, logic bombs, Trojan horses, worms, harmful components,
        corrupted data or other malicious software or harmful data);
        <br /> 5.3.5 to cause annoyance, inconvenience or needless anxiety;
        <br />
        5.3.6 to intercept or attempt to intercept any communications
        transmitted by way of a telecommunications system; <br /> 5.3.7 for a
        purpose other than which we have designed them or intended them to be
        used;
        <br /> 5.3.8 for any fraudulent purpose; <br /> 5.3.9 other than in
        conformance with accepted Internet practices and practices of any
        connected networks; <br /> 5.3.10 in any way which is calculated to
        incite hatred against any ethnic, religious or any other minority or is
        otherwise calculated to adversely affect any individual, group or
        entity; or <br /> 5.3.11 in such a way as to, or commit any act that
        would or does, impose an unreasonable or disproportionately large load
        on our infrastructure.
        <br /> 5.4 Prohibitions in relation to usage of Services, Mobile
        Application: Without limitation, you further undertake not to or permit
        anyone else to: -<br /> 5.4.1 resell any products or samples; <br />{" "}
        5.4.2 furnish false data including false names, addresses and contact
        details <br /> 5.4.3 attempt to circumvent our security or network
        including to access data not intended for you, log into a server or
        account you are not expressly authorized to access, or probe the
        security of other networks (such as running a port scan);
        <br /> 5.4.4 execute any form of network monitoring which will intercept
        data not intended for you; <br /> 5.4.5 enter into fraudulent
        interactions or transactions
        <br /> 5.4.6 extract data from or hack into the Mobile Application;
        <br /> 5.4.7 use the Services or Mobile Application in breach of these
        Terms and Conditions of Use; <br /> 5.4.8 engage in any unlawful
        activity in connection with the use of the Mobile Application or the
        Services; or <br /> 5.4.9 engage in any conduct which, in our exclusive
        reasonable opinion, restricts or inhibits any other customer from
        properly using or enjoying the Mobile Application or Services.
      </p>

      <h2>6. RULES ABOUT USE OF THE SERVICE AND THE MOBILE APPLICATION</h2>
      <p>
        6.1 We will use reasonable endeavors to correct any errors or omissions
        as soon as practicable after being notified of them. However, we do not
        guarantee that the Services or the Mobile Application will be free of
        faults, and we do not accept liability for any such faults, errors or
        omissions. In the event of any such error, fault or omission, you should
        report it by contacting us. <br /> 6.2 We do not warrant that your use
        of the Services or the Mobile Application will be uninterrupted, and we
        do not warrant that any information (or messages) transmitted via the
        Services or the Mobile Application will be transmitted accurately,
        reliably, in a timely manner or at all. Notwithstanding that we will try
        to allow uninterrupted access to the Services and the Mobile
        Application, access to the Services and the Mobile Application may be
        suspended, restricted or terminated at any time. <br /> 6.3 We do not
        give any warranty that the Services and the Mobile Application are free
        from viruses or anything else which may have a harmful effect on any
        technology. 6.4 We reserve the right to change, modify, substitute,
        suspend or remove without notice any information or Services on the
        Mobile Application from time to time. Your access to the Mobile
        Application and/or the Services may also be occasionally restricted to
        allow for repairs, maintenance or the introduction of new facilities or
        services. We will attempt to restore such access as soon as we
        reasonably can. For the avoidance of doubt, we reserve the right to
        withdraw any information or Services from the Mobile Application at any
        time. 6.5 We reserve the right to block access to and/or to edit or
        remove any material which in our reasonable opinion may give rise to a
        breach of these Terms and Conditions of Use.
      </p>

      <h2>7. DISCLAIMER AND EXCLUSION OF LIABILITY</h2>
      <p>
        7.1 The Mobile Application, the Services, the information on the Mobile
        Application and use of all related facilities are provided on an "as is,
        as available" basis without any warranties whether express or implied.
        <br />
        7.2 To the fullest extent permitted by applicable law, we disclaim all
        representations and warranties relating to the Mobile Application and
        its contents, including in relation to any inaccuracies or omissions in
        the Mobile Application, warranties of merchantability, quality, fitness
        for a particular purpose, accuracy, availability, non-infringement or
        implied warranties from course of dealing or usage of trade. <br /> 7.3
        We do not warrant that the Mobile Application will always be accessible,
        uninterrupted, timely, secure, error free or free from computer virus or
        other invasive or damaging code or that the Mobile Application will not
        be affected by any acts of God or other force majeure events, including
        inability to obtain or shortage of necessary materials, equipment
        facilities, power or telecommunications, lack of telecommunications
        equipment or facilities and failure of information technology or
        telecommunications equipment or facilities.
        <br /> 7.4 While we may use reasonable efforts to include accurate and
        up-to-date information on the Mobile Application, we make no warranties
        or representations as to its accuracy, timeliness or completeness.
        <br /> 7.5 We shall not be liable for any acts or omissions of any third
        parties howsoever caused, and for any direct, indirect, incidental,
        special, consequential or punitive damages, howsoever caused, resulting
        from or in connection with the mobile application and the Services
        offered in the mobile application, your access to, use of or inability
        to use the mobile application or the Services offered in the mobile
        application, reliance on or downloading from the Mobile Application
        and/or Services, or any delays, inaccuracies in the information or in
        its transmission including but not limited to damages for loss of
        business or profits, use, data or other intangible, even if we have been
        advised of the possibility of such damages.
        <br /> 7.6 We shall not be liable in contract, tort (including
        negligence or breach of statutory duty) or otherwise howsoever and
        whatever the cause thereof, for any indirect, consequential, collateral,
        special or incidental loss or damage suffered or incurred by you in
        connection with the Mobile Application and these Terms and Conditions of
        Use. For the purposes of these Terms and Conditions of Use, indirect or
        consequential loss or damage includes, without limitation, loss of
        revenue, profits, anticipated savings or business, loss of data or
        goodwill, loss of use or value of any equipment including software,
        claims of third parties, and all associated and incidental costs and
        expenses.
        <br /> 7.7 The above exclusions and limitations apply only to the extent
        permitted by law. None of your statutory rights as a consumer that
        cannot be excluded or limited are affected.
        <br /> 7.8 Notwithstanding our efforts to ensure that our system is
        secure, you acknowledge that all electronic data transfers are
        potentially susceptible to interception by others. We cannot, and do
        not, warrant that data transfers pursuant to the Mobile Application, or
        electronic mail transmitted to and from us, will not be monitored or
        read by others.
      </p>

      <h2>8. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        8.1 All editorial content, information, photographs, illustrations,
        artwork and other graphic materials, and names, logos and trademarks on
        the Mobile Application are protected by copyright laws and/or other laws
        and/or international treaties, and belong to us and/or our suppliers, as
        the case may be. These works, logos, graphics, sounds or images may not
        be copied, reproduced, retransmitted, distributed, disseminated, sold,
        published, broadcasted or circulated whether in whole or in part, unless
        expressly permitted by us and/or our suppliers, as the case may be.
        <br /> 8.2 Nothing contained on the Mobile Application should be
        construed as granting by implication, estoppel, or otherwise, any
        license or right to use any trademark displayed on the Mobile
        Application without our written permission. Misuse of any trademarks or
        any other content displayed on the Mobile Application is prohibited.
        <br /> 8.3 We will not hesitate to take legal action against any
        unauthorized usage of our trademarks, name or symbols to preserve and
        protect its rights in the matter. All rights not expressly granted
        herein are reserved. Other product and company names mentioned herein
        may also be the trademarks of their respective owners.
      </p>

      <h2>9. AMENDMENTS</h2>
      <p>
        9.1 We may periodically make changes to the contents of the Mobile
        Application, at any time and without notice. We assume no liability or
        responsibility for any errors or omissions in the content of the Mobile
        Application. <br />
        9.2 We reserve the right to amend these Terms and Conditions of Use from
        time to time. The revised Terms and Conditions of Use will be posted on
        the Mobile Application and shall take effect from the date of such
        posting. You are advised to review these terms and conditions
        periodically as they are binding upon you.
      </p>

      <h2>10. Privacy Policy</h2>
      <p>
        10.1 By accessing the Mobile Application and by continuing to use the
        Services offered, you are deemed to have accepted this Privacy Policy,
        and in particular, you are deemed to have consented to our use and
        disclosure of your personal information in the manner prescribed in this
        Privacy Policy and for the purposes set out in Clauses 3.7. We reserve
        the right to amend this Privacy Policy from time to time. If you
        disagree with any part of this Privacy Policy, you must immediately
        discontinue your access to the Mobile Application and your use of the
        Services.
        <br /> 10.2 As part of the normal operation of our Services, we collect,
        use, information about you. Accordingly, we have developed this Privacy
        Policy in order for you to understand how we collect, use, communicate
        and disclose and make use of your personal information when you use the
        Services on the Mobile Application: - (a) We will collect and use
        personal information solely with the objective of fulfilling those
        purposes specified by us and for other compatible purposes, unless we
        obtain the consent of the individual concerned or as required by law.
        (b) We will only retain personal information as long as necessary for
        the fulfillment of those purposes. (c) We will collect personal
        information by lawful and fair means and, where appropriate, with the
        knowledge or consent of the individual concerned. (d) Personal
        information should be relevant to the purposes for which it is to be
        used, and, to the extent necessary for those purposes, should be
        accurate, complete, and up to date. (e) We will protect personal
        information by reasonable security safeguards against loss or theft, as
        well as unauthorized access, disclosure, copying, use or modification.
        We are committed to conducting our business in accordance with these
        principles in order to ensure that the confidentiality of personal
        information is protected and maintained.
      </p>

      <h2>11. APPLICABLE LAW AND JURISDICTION</h2>
      <p>
        11.1 The Egyptian law provisions apply to Terms and Conditions of Use.
        <br />
        11.2 These Terms and Conditions of Use are subject to and interpreted in
        accordance with the provisions of Egyptian law, and the Courts of Cairo
        are competent to deal with any dispute arising from the application or
        changing of any of its items .
      </p>
    </div>
  );
};

export default Terms;
