import React, { useState } from "react";
import classes from "./ContactUs.module.css";
import axios from "axios";
import toast from "react-hot-toast";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [company, setCompany] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      phone_number: phoneNumber,
      subject,
      body: message,
    };

    setbuttonDisabled(true);

    if (name && email && phoneNumber && subject && message) {
      try {
        await axios.post(
          `/sGsBE3l0SSPsTJzfenZN0JgLmRuVmZZT/v1/contact-us/?lang=en`,
          data,
          {
            headers: {
              withCredentials: true,
            },
          }
        );
        setName("");
        setEmail("");
        setPhoneNumber("");
        setSubject("");
        setMessage("");
        setbuttonDisabled(false);
        setCompany("");

        toast.success("Your message has been sent!");

        // Sending some data to salesforce server that sales team needs them
        // const salesData = {
        //   captcha_settings: {
        //     keyname: "CompanyWebSiteKey",
        //     fallback: "true",
        //     orgId: "00D5G000004nX31",
        //     ts: "",
        //   },
        //   oid: "00D5G000004nX31",
        //   retURL: "http://",
        //   first_name: name.split(" ")[0],
        //   last_name: name.split(" ")[1],
        //   email,
        //   company: company,
        //   phone: phoneNumber,
        // };
        // console.log(salesData);

        // var params = new URLSearchParams();
        // params.append("captcha_settings", {
        //   keyname: "CompanyWebSiteKey",
        //   fallback: "true",
        //   orgId: "00D5G000004nX31",
        //   ts: "",
        // });
        // params.append("oid", "00D5G000004nX31");
        // params.append("retURL", "http://");
        // params.append("first_name", name.split(" ")[0]);
        // params.append("last_name", name.split(" ")[1]);
        // params.append("email", email);
        // params.append("company", company);
        // params.append("phone", phoneNumber);

        // await axios.post(
        //   `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`,
        //   params
        // );
      } catch (e) {
        if (e.response) {
          if (e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong plaese try again later!");
          }
        } else {
          toast.error("Please make sure of your internet connections!");
        }
      }
    } else {
      toast.error("Please fill all fields!");
    }

    setbuttonDisabled(false);
  };

  return (
    <div className={classes.contactUs} id="contact">
      <div className={classes.ContactUs__text}>
        <h2>Contact us</h2>
        <p>
          We are always ready to answer any question you have. Email us and
          we'll help you find the best solution for your business.
        </p>
      </div>
      <div className={classes.ContactUs_form}>
        <form onSubmit={handleSubmit}>
          <div className={classes.form__header}>
            <div>
              <label>Name</label>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mr-2"
              />
            </div>
            <div>
              <label>Email</label>
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <label>Phone Number</label>
          <input
            type="text"
            placeholder="Your Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <label>Company</label>
          <input
            type="text"
            placeholder="Your Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <label>Subject</label>
          <input
            type="text"
            placeholder="Your Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <label>Message</label>
          <input
            className={classes.wideInput}
            type="text"
            placeholder="Write your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            style={{ backgroundColor: buttonDisabled ? "grey" : "#62cbc9" }}
          >
            Send message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
