import classes from "./Industries.module.css";
import industriesImg from "../../assets/images/industries.png";
import valid from "../../assets/svg/valid.svg";

const Industries = () => {
  return (
    <div className={classes.Industries} id="industries">
      <div className={classes.Industries__img}>
        <img src={industriesImg} alt="man verifying his fingerprint" />
      </div>
      <div className={classes.Industries__content}>
        <h2>INDUSTRIES</h2>
        <h3>Online ID Verification Technologies</h3>
        <ul>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Financial Services</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Health Care</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Hospitality</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Travel</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Transport</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Retail & E-commerce</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Education</p>
          </li>
          <li>
            <img src={valid} alt="green correct sign" />
            <p>Gaming</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Industries;
