import classes from "./AboutUs.module.css";
import aboutus from "../../assets/images/about.png";

const AboutUs = () => {
  return (
    <div className={classes.aboutUs} id="about">
      <div className={classes.aboutUs__info}>
        <h2>ABOUT US</h2>
        <span>A reg-tech that is re-defining accessibility </span>
        <p>
          Valify is an Egypt-based RegTech company specializing in digital
          identity infrastructure technology. The company was founded in 2018
          following the global movement towards financial inclusion as well as
          the massive increase in digital service providers.Currently a market
          leader in Egypt, Valify offers its services to financial institutions,
          telecos, e-commerce platforms, as well as many others in various
          sectors.
        </p>
      </div>
      <div className={classes.aboutUs__img}>
        <img src={aboutus} alt="man holding a pen" />
      </div>
    </div>
  );
};

export default AboutUs;
