import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Header from "./components/header/Header";
import OurPartners from "./components/ourPartners/OurPartners";
import AboutUs from "./components/aboutUs/AboutUs";
import OurClients from "./components/ourClients/OurClients";
import OurServices from "./components/ourServices/OurServices";
import Industries from "./components/industries/Industries";
import MobiileApp from "./components/mobileApp/MobileApp";
import UseCases from "./components/useCases/UseCases";
import ContactUs from "./components/contactUs/ContactUs";
import Footer from "./components/footer/Footer";
import ModalComp from "./components/modal/Modal";
import Terms from "./components/terms/Terms";
import withClearCache from "./ClearCache";
import RouteChangeTracker from "./components/routerChange/RouterChange";
import ProfilePageWrapper from "./components/profilePageWrapper/ProfilePageWrapper";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "1.6rem",
            padding: "1.6rem",
          },
        }}
      />
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/company-profile" exact>
            <ProfilePageWrapper />
            <ContactUs />
            <Footer />
          </Route>

          <Route path="/terms-of-use" exact>
            <Header openModal={showModal} />
            <Terms />
            <ModalComp
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            />
            <Footer />
          </Route>

          <Route path="/" exact>
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                duration: 10000,
                style: {
                  fontSize: "1.6rem",
                  padding: "1.6rem",
                },
              }}
            />
            <Header openModal={showModal} />
            <OurClients />
            <AboutUs />
            <OurPartners />
            <OurServices />
            <Industries />
            <MobiileApp />
            <UseCases />
            <ContactUs />
            <Footer />
            <ModalComp
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            />
            <RouteChangeTracker />
          </Route>

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
