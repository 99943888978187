import classes from "./UseCases.module.css";
import authentication from "../../assets/images/eKYC.jpg";
import onboarding from "../../assets/images/onboarding.png";
import branch from "../../assets/images/branch.png";
import identity from "../../assets/images/identity.png";
// import age from "../../assets/images/age.png";
import aml from "../../assets/images/aml.jpg";
import documents from "../../assets/images/document verification.jpg";

const UseCases = () => {
  return (
    <div className={classes.useCases} id="usecases">
      <h2>USE CASES</h2>
      <div className={classes.useCases__header}>
        <h3>Digital Identity Verification</h3>
        <p>
          Discover how Digital Identity Verification can scale your business and
          help drive new growth through digital channels while maintaining
          regulatory compliance
        </p>
      </div>
      <div className={classes.useCases__grid}>
        <div className={classes.useCases__item}>
          <div className={classes.useCases__img}>
            <img src={onboarding} alt="girl waving with her hand" />
          </div>
          <span>Digital User Onboarding</span>
        </div>

        <div className={classes.useCases__item}>
          <div className={classes.useCases__img}>
            <img src={identity} alt="man showing his right hand" />
          </div>
          <span>Identity Verification</span>
        </div>

        <div className={classes.useCases__item}>
          <div className={classes.useCases__img}>
            <img src={authentication} alt="man wearing glasses" />
          </div>
          <span>Biometric Authentication</span>
        </div>

        <div className={classes.useCases__item}>
          <div className={classes.useCases__img}>
            <img
              src={documents}
              alt="man uploading some documents on his laptop"
            />
          </div>
          <span>Document Authentication</span>
        </div>

        <div className={classes.useCases__item}>
          <div className={classes.useCases__img}>
            <img src={branch} alt="group of peopla gathering in a branch" />
          </div>
          <span>Branch Automation</span>
        </div>

        <div className={classes.useCases__item}>
          <div className={classes.useCases__img}>
            <img src={aml} alt="man using holograms of users" />
          </div>
          <span>AML Compliance</span>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
