import classes from "../ourPartners/OurPartners.module.css";
import React from "react";
import aman from "../../assets/svg/clients/Aman.svg";
import jumia from "../../assets/svg/clients/jumia.svg";
import thndr from "../../assets/svg/clients/thndrLogo.svg";
import abk from "../../assets/svg/clients/abk.svg";

import style from "./OurClients.module.css";

const OurClients = () => {
  return (
    <div className={classes.ourPartners} id="clients">
      <h2>OUR CLIENTS</h2>
      <p>Our work defines our vision</p>

      <ul className={style.grid}>
        <li className={style.grid__items}>
          <img src={aman} alt="Aman Logo" className={style.big__logo} />
        </li>

        <li className={style.grid__items}>
          <img src={thndr} alt="Thndr Logo" />
        </li>

        <li className={style.grid__items}>
          <img src={jumia} alt="Jumia Pay Logo" className={style.big__logo} />
        </li>
        <li className={style.grid__items}>
          <img src={abk} alt="ABK Al AHLY Logo" />
        </li>
      </ul>
    </div>
  );
};

export default OurClients;
