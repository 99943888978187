import classes from "./OurPartners.module.css";
import React, { Component } from "react";
import Slider from "react-slick";
import microsoft from "../../assets/svg/partners/micro.svg";
import oracle from "../../assets/svg/partners/orac.svg";
import accelero from "../../assets/svg/partners/acc.svg";
import ventures from "../../assets/svg/partners/starVen.svg";
import villa from "../../assets/svg/partners/villageCapital.svg";
import fourdx from "../../assets/svg/partners/4dx.svg";

class AutoPlay extends Component {
  render() {
    const settings = {
      // dots: true,
      // infinite: false,
      // slidesToShow: 4,
      // slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 0,
      cssEase: "linear",
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,

      // pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 0,
            cssEase: "linear",
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            autoplay: false,
            speed: 500,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className={classes.slider__item}>
            <img
              src={microsoft}
              alt="microsoft for startups logo"
              // style={{
              //   width: "50%",
              //   height: "50%",
              //   position: "relative",
              //   top: "3rem",
              // }}
            />
          </div>
          <div className={classes.slider__item}>
            <img
              className={classes.accelero}
              src={accelero}
              alt="accelero logo"
              // style={{ position: "relative", top: "3rem" }}
            />
          </div>
          <div className={classes.slider__item}>
            <img
              src={oracle}
              alt="oracle for startups logo"
              // style={{
              //   width: "50%",
              //   height: "50%",
              //   position: "relative",
              //   top: "3rem",
              // }}
            />
          </div>
          <div className={classes.slider__item}>
            <img
              src={ventures}
              alt="star venture logo"
              // style={{
              //   width: "50%",
              //   height: "50%",
              //   position: "relative",
              //   top: "3rem",
              // }}
            />
          </div>
          <div className={classes.slider__item}>
            <img
              src={fourdx}
              alt="star venture logo"
              // style={{
              //   width: "50%",
              //   height: "50%",
              //   position: "relative",
              //   top: "1rem",
              // }}
            />
          </div>
          <div className={classes.slider__item}>
            <img
              src={villa}
              alt="villa capital logo"
              // style={{
              //   width: "50%",
              //   height: "50%",
              //   position: "relative",
              //   top: "-20px",
              // }}
            />
          </div>
        </Slider>
      </div>
    );
  }
}

const OurPartners = () => {
  return (
    <div className={classes.ourPartners} id="partners">
      <h2>OUR PARTNERS</h2>
      <p>We are proud of our cooperation</p>
      <AutoPlay />
    </div>
  );
};

export default OurPartners;
