import classes from "./Mobile.module.css";
import mobileImg from "../../assets/images/app.png";
import apple from "../../assets/svg/apple.svg";
import play from "../../assets/svg/play.svg";

const MobiileApp = () => {
  return (
    <div className={classes.mobile} id="showcase">
      <div className={classes.mobile__content}>
        <h2>MOBILE APP</h2>
        <h3>Showcase App</h3>
        <p>
          In order to bring our services to you, we have brought you the Valify
          Showcase App. Fill out the form through the "Try it Out!" button in
          the first section and you'll be able to easily test out various Valify
          services straight from your smartphone. You will also get full access
          to the Valify Portal where you will be able to check results,
          analytics, and reports.
          <br /> Download Now!
        </p>
        <div className={classes.mobile__btns}>
          <a
            href="https://apps.apple.com/eg/app/valify/id1589030299"
            target="_blank"
            rel="noreferrer"
          >
            <button className="mr-2">
              <img src={apple} alt="apple logo" /> <span> App Store</span>
            </button>
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=me.valify.valifyshowcaseapp"
            target="_blank"
            rel="noreferrer"
          >
            <button>
              <img src={play} alt="play store logo" /> <span> Play Store</span>
            </button>
          </a>
        </div>
      </div>
      <div className={classes.mobile__img}>
        <img src={mobileImg} alt="scanning qr code with mobile" />
      </div>
    </div>
  );
};

export default MobiileApp;
